import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import ApiManager from "../../utils/ApiManager";
import Modal from "../Modal";
import RejectReason from "./RejectReason";

interface PropTypes {
  isVisible: boolean;
  onHide: () => void;
  driverId: string | undefined;
  onApproveOrRejectSuccess: () => void;
}

const ResubmittedDocuments = ({
  isVisible,
  onHide,
  driverId,
  onApproveOrRejectSuccess,
}: PropTypes) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [isDocumentsError, setIsDocumentsError] = useState(false);
  const [isRejectReasonModalVisible, setIsRejectReasonModalVisible] =
    useState(false);
  // const { data, isError: isDocumentsError } =
  //   useGetResubmitedDocumentsQuery(driverId);
  // const [approveOrRejectResubmittedDocuments, { isLoading, isError }] =
  //   useApproveOrRejectResubmittedDocumentsMutation();

  const handleGetResubmittedDocuments = async () => {
    const res: any = await new ApiManager().getResubmittedDocuments(
      driverId || ""
    );
    if (res?.success) {
      setData(res.data);
      setIsDocumentsError(false);
    } else {
      setIsDocumentsError(true);
    }
  };

  const handleApproveOrReject = async (
    isApproved: boolean,
    reason?: string
  ) => {
    setIsLoading(true);
    const res = await new ApiManager().approveOrRejectResubmittedDocuments(
      driverId || "",
      isApproved,
      !isApproved && reason ? reason : ""
    );
    if (res?.success) {
      toast(`Documents ${isApproved ? "Approved" : "Rejected"} Successfully`, {
        position: "top-right",
        type: "success",
      });
    } else {
      toast("Something went wrong", { position: "top-right", type: "error" });
    }
    setIsLoading(false);
    setIsRejectReasonModalVisible(false);
    onApproveOrRejectSuccess();
    onHide();
  };

  const documents = useMemo(() => {
    if (isDocumentsError) return [];
    const newDrivingLicenseFile = {
      name: "New Driving License File",
      file: data?.newDrivingLicenseFile,
    };
    const newVehicleInsuranceFile = {
      name: "New Vehicle Insurance File",
      file: data?.newVehicleInsuranceFile,
    };
    const newVehicleRegistrationFile = {
      name: "New Vehicle Registration File",
      file: data?.newVehicleRegistrationFile,
    };

    const docs = [
      newDrivingLicenseFile,
      newVehicleInsuranceFile,
      newVehicleRegistrationFile,
    ];

    const nonEmptyDocuments = docs.filter((item) => item.file !== undefined);

    return nonEmptyDocuments;
  }, [data, isDocumentsError]);

  useEffect(() => {
    handleGetResubmittedDocuments();
  }, [driverId]);

  return (
    <>
      <Modal
        heading="Resubmitted Documents"
        isVisible={isVisible}
        onHide={onHide}
      >
        <div className="w-full grid grid-cols-2 mt-10 h-full">
          {documents.map(
            (doc) =>
              doc.file !== "" && (
                <a target="_blank" href={doc.file}>
                  <button
                    style={{
                      background:
                        "linear-gradient(357.95deg, #339500 1.23%, #77CB02 97.91%)",
                    }}
                    className="px-4 mb-4 py-2 text-white rounded-lg"
                  >
                    {doc.name}
                  </button>
                </a>
              )
          )}
        </div>
        {documents.length > 0 && (
          <div className="w-full flex gap-4 items-center justify-center mt-10">
            <button
              disabled={isLoading}
              onClick={() => handleApproveOrReject(true)}
              className="bg-primary text-white rounded px-4 py-2"
            >
              Approve
            </button>
            <button
              disabled={isLoading}
              onClick={() => setIsRejectReasonModalVisible(true)}
              // onClick={() => handleApproveOrReject(false)}
              className="bg-red-600 text-white rounded px-4 py-2"
            >
              Reject
            </button>
          </div>
        )}
      </Modal>
      <RejectReason
        onConfirm={(reason) => {
          handleApproveOrReject(false, reason);
        }}
        isVisible={isRejectReasonModalVisible}
        onHide={() => setIsRejectReasonModalVisible(false)}
        isLoading={isLoading}
      />
    </>
  );
};

export default ResubmittedDocuments;
