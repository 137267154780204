import React from "react";
import Modal from "../Modal";
import Textarea from "../Textarea";
import Button from "../Button";
import { useFormik } from "formik";
import * as Yup from "yup";

interface RejectReasonProps {
  isVisible: boolean;
  onHide: () => void;
  onConfirm: (reason: string) => void;
  isLoading: boolean;
}

const RejectReason = ({
  isVisible,
  onHide,
  onConfirm,
  isLoading,
}: RejectReasonProps) => {
  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().trim().required("This field is required"),
    }),
    onSubmit: (values) => {
      onConfirm(values.reason);
    },
  });

  return (
    <Modal heading="Reason for Rejecting" isVisible={isVisible} onHide={onHide}>
      <Textarea
        error={formik.errors.reason}
        isInvalid={Boolean(formik.touched.reason && formik.errors.reason)}
        onBlur={formik.handleBlur}
        name="reason"
        onChange={formik.handleChange}
        label="Enter Reason"
      />
      <div className="text-center mt-4">
        <Button
          isLoading={isLoading}
          onClick={() => formik.handleSubmit()}
          className="w-56"
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default RejectReason;
