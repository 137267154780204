import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ApiManager from "../utils/ApiManager";
import {
  formatDate,
  isFileFormatValid,
  isFileSizeValid,
  isValidPhoneNumber,
} from "../utils/helpers";
import Button from "./Button";
import DatePicker from "./DatePicker";
import FileInput from "./FileInput";
import Input from "./Input";
import Modal from "./Modal";
import PasswordInput from "./PasswordInput";
import PhoneInput from "./PhoneInput";
import Select from "./Select";

interface PropTypes {
  isVisible: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

type VehicleType = {
  label: string;
  value: string;
};

const AddDriver = ({ isVisible, onHide, onSuccess }: PropTypes) => {
  const [vehicleTypes, setVehicleTypes] = useState<VehicleType[]>([]);
  const [vehicleMakes, setVehicleMakes] = useState<VehicleType[]>([]);
  const [vehicleModels, setVehicleModels] = useState<VehicleType[]>([]);
  const [showCustomType, setShowCustomType] = useState(false);
  const [showCustomMake, setShowCustomMake] = useState(false);
  const [showCustomModel, setShowCustomModel] = useState(false);
  const [allStates, setAllStates] = useState<
    { label: string; value: string }[]
  >([]);

  const form = useRef<HTMLFormElement>(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
      vehicleNumber: "",
      vehicleMakeYear: "",
      vehicleType: "",
      vehicleMake: "",
      vehicleModel: "",
      drivingLicenseFile: "",
      profileImage: "",
      zipCode: "",
      driverLicenseNumber: "",
      driverLicenseState: "",
      vehicleInsuranceFile: "",
      dateOfBirth: "",
      state: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .trim()
        .matches(/^[A-Za-z\s]+$/, "Name must contain only letters")
        .required("This field is required"),
      email: Yup.string()
        .trim()
        .email("Email must be valid")
        .required("This field is required"),
      password: Yup.string()
        .trim()
        .min(6, "Password must be at least 6 characters")
        .required("This field is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("This field is required"),
      phone: Yup.string()
        .trim()
        .test("phone", "Invalid phone number", function (value) {
          if (!value) return false;
          return isValidPhoneNumber(value);
        })
        .required("This field is required"),
      vehicleNumber: Yup.string().trim().required("This field is required"),
      vehicleMakeYear: Yup.number()
        .positive()
        .required("This field is required")
        .test(
          "len",
          "Must be exactly 4 digits",
          (val) => val.toString().length === 4
        )
        .test(
          "valid-year",
          `Year must be between 1900 and ${new Date().getFullYear() + 1}`,
          (val) => {
            const currentYear = new Date().getFullYear();
            return val >= 1900 && val <= currentYear + 1;
          }
        ),
      vehicleType: Yup.string().trim().required("This field is required"),
      vehicleMake: Yup.string()
        .trim()
        .required("This field is required")
        .when("vehicleType", {
          is: "other",
          then: (schema) =>
            schema.min(
              3,
              "Must be at least 3 characters when Vehicle Type is Other"
            ),
        }),
      vehicleModel: Yup.string()
        .trim()
        .required("This field is required")
        .when("vehicleType", {
          is: "other",
          then: (schema) =>
            schema.min(
              3,
              "Must be at least 3 characters when Vehicle Type is Other"
            ),
        }),
      drivingLicenseFile: Yup.mixed()
        .required("This field is required")
        .test("size", "File size must be 5MB or less", (value) => {
          if (value && value instanceof File) {
            return isFileSizeValid(value);
          }
        })
        .test("format", "File must either be PDF or Image", (value) => {
          if (value && value instanceof File) {
            return isFileFormatValid(value);
          }
        }),
      vehicleInsuranceFile: Yup.mixed()
        .required("This field is required")
        .test("size", "File size must be 5MB or less", (value) => {
          if (value && value instanceof File) {
            return isFileSizeValid(value);
          }
        })
        .test("format", "File must either be PDF or Image", (value) => {
          if (value && value instanceof File) {
            return isFileFormatValid(value);
          }
        }),
      profileImage: Yup.mixed()
        .required("This field is required")
        .test("size", "File size must be 5MB or less", (value) => {
          if (value && value instanceof File) {
            return isFileSizeValid(value);
          }
        })
        .test("format", "File must either be PDF or Image", (value) => {
          if (value && value instanceof File) {
            return isFileFormatValid(value);
          }
        }),
      zipCode: Yup.number()
        .positive()
        .required("This field is required")
        .test(
          "len",
          "Zip code must be 5 digits",
          (val) => val.toString().length === 5
        ),
      driverLicenseNumber: Yup.string()
        .trim()
        .required("This field is required"),
      driverLicenseState: Yup.string()
        .trim()
        .required("This field is required"),
      dateOfBirth: Yup.string()
        .trim()
        .required("This field is required")
        .test("age", "Driver must be at least 18 years old", function (value) {
          if (!value) return false;
          const today = new Date();
          const birthDate = new Date(value);
          let age = today.getFullYear() - birthDate.getFullYear();
          const monthDiff = today.getMonth() - birthDate.getMonth();

          if (
            monthDiff < 0 ||
            (monthDiff === 0 && today.getDate() < birthDate.getDate())
          ) {
            age--;
          }

          return age >= 18;
        }),
      state: Yup.string().trim().required("This field is required"),
    }),
    onSubmit: async (values) => {
      let res = await new ApiManager().createDriver({
        fullName: values.name,
        email: values.email?.toLowerCase(),
        password: values.password,
        phone: `+${values.phone}`,
        vehicleNumber: values.vehicleNumber,
        profileImage: values.profileImage,
        drivingLicenseFile: values.drivingLicenseFile,
        zipCode: values.zipCode,
        vehicleType: values.vehicleType,
        vehicleMake: values.vehicleMake,
        vehicleModel: values.vehicleModel,
        vehicleMakeYear: values.vehicleMakeYear,
        dob: formatDate(values.dateOfBirth),
        driverLicenseNumber: values.driverLicenseNumber,
        driverLicenseState: values.driverLicenseState,
        state: values.state,
        vehicleInsuranceFile: values.vehicleInsuranceFile,
      });
      if (res?.success) {
        toast("Driver created successfully", {
          type: "success",
        });
        formik.resetForm();
        onSuccess();
        onHide();
      } else {
        toast(res?.err?.error, {
          type: "error",
        });
      }
    },
  });

  const handleGetVehicleDetails = async () => {
    const res: any = await new ApiManager().getVehicleDetails();
    if (res.success) {
      setVehicleTypes([...res.data?.types, { label: "Other", value: "other" }]);
    }
  };

  const handleGetVehicleMakes = async () => {
    const res = await new ApiManager().getVehicleDetails({
      type: formik.values.vehicleType,
    });
    if (res.success) {
      setVehicleMakes([
        ...res?.data?.makes,
        { label: "Other", value: "other" },
      ]);
    }
  };

  const handleGetVehicleModels = async () => {
    const res = await new ApiManager().getVehicleDetails({
      type: formik.values.vehicleType,
      make: formik.values.vehicleMake,
    });
    if (res.success) {
      setVehicleModels([
        ...res?.data?.models,
        { label: "Other", value: "other" },
      ]);
    }
  };
  const handleVehicleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value === "other") {
      setShowCustomType(true);
      formik.setFieldValue("vehicleType", "");
    } else {
      setShowCustomType(false);
      formik.setFieldValue("vehicleType", value);
    }
    formik.setFieldValue("vehicleMake", "");
    formik.setFieldValue("vehicleModel", "");
    setShowCustomMake(false);
    setShowCustomModel(false);
  };

  const handleVehicleMakeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value === "other") {
      setShowCustomMake(true);
      formik.setFieldValue("vehicleMake", "");
    } else {
      setShowCustomMake(false);
      formik.setFieldValue("vehicleMake", value);
    }
    formik.setFieldValue("vehicleModel", "");
    setShowCustomModel(false);
  };

  const handleVehicleModelChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = e.target.value;
    if (value === "other") {
      setShowCustomModel(true);
      formik.setFieldValue("vehicleModel", "");
    } else {
      setShowCustomModel(false);
      formik.setFieldValue("vehicleModel", value);
    }
  };

  useEffect(() => {
    handleGetVehicleDetails();
  }, []);

  const handleGetAllStates = async () => {
    const res = await new ApiManager().getAllStates();
    if (res.success) {
      setAllStates(res.data?.states);
    }
  };

  useEffect(() => {
    handleGetAllStates();
  }, []);

  useEffect(() => {
    if (formik.values.vehicleType && !showCustomType) {
      handleGetVehicleMakes();
    }
  }, [formik.values.vehicleType, showCustomType]);

  useEffect(() => {
    if (formik.values.vehicleMake && !showCustomType && !showCustomMake) {
      handleGetVehicleModels();
    }
  }, [formik.values.vehicleMake, showCustomType, showCustomMake]);

  const renderVehicleTypeField = () => {
    if (showCustomType) {
      return (
        <Input
          onChange={formik.handleChange}
          name="vehicleType"
          label="Vehicle Type: *"
          onBlur={formik.handleBlur}
          value={formik.values.vehicleType}
          isInvalid={Boolean(
            formik.touched.vehicleType && formik.errors.vehicleType
          )}
          error={formik.errors.vehicleType}
          placeholder="Enter Vehicle Type"
        />
      );
    }

    return (
      <Select
        onChange={handleVehicleTypeChange}
        name="vehicleType"
        label="Vehicle Type: *"
        options={vehicleTypes}
        value={formik.values.vehicleType}
        error={formik.errors.vehicleType}
        isInvalid={Boolean(
          formik.touched.vehicleType && formik.errors.vehicleType
        )}
        placeholder="Choose Vehicle Type"
        disabled={vehicleTypes.length === 0}
      />
    );
  };

  const renderVehicleMakeField = () => {
    if (showCustomType || showCustomMake) {
      return (
        <Input
          onChange={formik.handleChange}
          name="vehicleMake"
          label="Vehicle Make: *"
          onBlur={formik.handleBlur}
          value={formik.values.vehicleMake}
          isInvalid={Boolean(
            formik.touched.vehicleMake && formik.errors.vehicleMake
          )}
          error={formik.errors.vehicleMake}
          placeholder="Enter Vehicle Make"
        />
      );
    }

    return (
      <Select
        onChange={handleVehicleMakeChange}
        name="vehicleMake"
        label="Vehicle Make: *"
        options={vehicleMakes}
        onBlur={formik.handleBlur}
        value={formik.values.vehicleMake}
        isInvalid={Boolean(
          formik.touched.vehicleMake && formik.errors.vehicleMake
        )}
        error={formik.errors.vehicleMake}
        placeholder="Choose Vehicle Make"
        disabled={formik.values.vehicleType === "" || vehicleMakes.length === 0}
      />
    );
  };

  const renderVehicleModelField = () => {
    if (showCustomType || showCustomMake || showCustomModel) {
      return (
        <Input
          onChange={formik.handleChange}
          name="vehicleModel"
          label="Vehicle Model: *"
          onBlur={formik.handleBlur}
          value={formik.values.vehicleModel}
          isInvalid={Boolean(
            formik.touched.vehicleModel && formik.errors.vehicleModel
          )}
          error={formik.errors.vehicleModel}
          placeholder="Enter Vehicle Model"
        />
      );
    }

    return (
      <Select
        onBlur={formik.handleBlur}
        onChange={handleVehicleModelChange}
        name="vehicleModel"
        label="Vehicle Model: *"
        value={formik.values.vehicleModel}
        options={vehicleModels}
        error={formik.errors.vehicleModel}
        isInvalid={Boolean(
          formik.touched.vehicleModel && formik.errors.vehicleModel
        )}
        placeholder="Choose Vehicle Model"
        disabled={
          formik.values.vehicleMake === "" || vehicleModels.length === 0
        }
      />
    );
  };

  // Reset custom field states when modal is closed
  useEffect(() => {
    if (!isVisible) {
      setShowCustomType(false);
      setShowCustomMake(false);
      setShowCustomModel(false);
    }
  }, [isVisible]);

  return (
    <Modal
      isVisible={isVisible}
      onHide={() => {
        formik.resetForm();
        onHide();
      }}
      heading="Add New Driver"
    >
      <form
        ref={form}
        onSubmit={formik.handleSubmit}
        className="w-full grid grid-cols-2 gap-5 py-10 bg-white rounded px-8 justify-center"
      >
        <Input
          label="Full Name: *"
          name="name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={Boolean(formik.touched.name && formik.errors.name)}
          error={formik.errors.name}
          placeholder="Enter Driver's Name"
        />
        <Input
          isInvalid={Boolean(formik.touched.email && formik.errors.email)}
          label="Email: *"
          name="email"
          type="email"
          autoComplete="off"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.email}
          placeholder="Enter Driver's Email"
        />
        <PasswordInput
          label="Password: *"
          name="password"
          type="password"
          isInvalid={Boolean(formik.touched.password && formik.errors.password)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.password}
          placeholder="Enter Driver's Password"
          autoComplete="new-password"
          className="input-primary"
        />
        <PasswordInput
          className="input-primary"
          label="Confirm Password: *"
          value={formik.values.confirmPassword}
          onBlur={formik.handleBlur}
          autoComplete="new-password"
          name="confirmPassword"
          onChange={formik.handleChange}
          error={formik.errors.confirmPassword}
          type="password"
          isInvalid={Boolean(
            formik.touched.confirmPassword && formik.errors.confirmPassword
          )}
          placeholder="Confirm Driver's Password"
        />
        <PhoneInput
          name="phone"
          label="Phone: *"
          onChange={(e) => formik.setFieldValue("phone", e)}
          placeholder="Enter Driver's Phone"
          onBlur={formik.handleBlur}
          error={formik.errors.phone}
          isInvalid={Boolean(formik.touched.phone && formik.errors.phone)}
          value={formik.values.phone}
        />

        <Input
          label="Vehicle Number: *"
          name="vehicleNumber"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.vehicleNumber}
          placeholder="Enter Vehicle Number"
          isInvalid={Boolean(
            formik.touched.vehicleNumber && formik.errors.vehicleNumber
          )}
        />
        <Input
          label="Vehicle Make Year: *"
          name="vehicleMakeYear"
          onChange={formik.handleChange}
          type="number"
          onBlur={formik.handleBlur}
          error={formik.errors.vehicleMakeYear}
          placeholder="Enter Vehicle Make Year"
          isInvalid={Boolean(
            formik.touched.vehicleMakeYear && formik.errors.vehicleMakeYear
          )}
        />

        {renderVehicleTypeField()}
        {renderVehicleMakeField()}
        {renderVehicleModelField()}

        <FileInput
          label="Profile Image: *"
          error={formik.errors.profileImage}
          onChange={(e) => {
            if (e.target.files) {
              formik.setFieldValue("profileImage", e.target.files[0]);
            }
          }}
          isInvalid={Boolean(
            formik.touched.profileImage && formik.errors.profileImage
          )}
        />

        <FileInput
          label="Vehicle Insurance File: *"
          error={formik.errors.vehicleInsuranceFile}
          onChange={(e) => {
            if (e.target.files) {
              formik.setFieldValue("vehicleInsuranceFile", e.target.files[0]);
            }
          }}
          isInvalid={Boolean(
            formik.touched.vehicleInsuranceFile &&
              formik.errors.vehicleInsuranceFile
          )}
        />

        <FileInput
          label="Driving License: *"
          error={formik.errors.drivingLicenseFile}
          onChange={(e) => {
            if (e.target.files) {
              formik.setFieldValue("drivingLicenseFile", e.target.files[0]);
            }
          }}
          isInvalid={Boolean(
            formik.touched.drivingLicenseFile &&
              formik.errors.drivingLicenseFile
          )}
        />

        <Input
          label="Zip Code: *"
          name="zipCode"
          onChange={formik.handleChange}
          type="number"
          onBlur={formik.handleBlur}
          error={formik.errors.zipCode}
          placeholder="Enter ZipCode: "
          isInvalid={Boolean(formik.touched.zipCode && formik.errors.zipCode)}
        />

        <Input
          label="Driver License Number: *"
          name="driverLicenseNumber"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.driverLicenseNumber}
          isInvalid={Boolean(
            formik.touched.driverLicenseNumber &&
              formik.errors.driverLicenseNumber
          )}
          placeholder="Enter Driver License Number"
        />

        <Select
          options={allStates}
          label="Driver License State: *"
          name="driverLicenseState"
          value={formik.values.driverLicenseState}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.driverLicenseState}
          isInvalid={Boolean(
            formik.touched.driverLicenseState &&
              formik.errors.driverLicenseState
          )}
          placeholder="Enter Driver License State"
        />
        <DatePicker
          placeholderText="Enter Date of Birth"
          label="Date of Birth *"
          selected={
            formik.values.dateOfBirth
              ? new Date(formik.values.dateOfBirth)
              : null
          }
          onChange={(e) =>
            formik.setFieldValue("dateOfBirth", formatDate(e?.toString() || ""))
          }
          onBlur={formik.handleBlur}
          isInvalid={Boolean(
            formik.touched.dateOfBirth && formik.errors.dateOfBirth
          )}
          error={formik.errors.dateOfBirth}
        />
        {/* <Input
          name="dateOfBirth"
          value={formik.values.dateOfBirth}
          onChange={formik.handleChange}
          label="Date of Birth: *"
          onBlur={formik.handleBlur}
          isInvalid={Boolean(
            formik.touched.dateOfBirth && formik.errors.dateOfBirth
          )}
          error={formik.errors.dateOfBirth}
          placeholder="Enter Date of Birth"
          type="date"
        /> */}
        {/* <DatePicker
          name="dateOfBirth"
          selectedDate={formik.values.dateOfBirth}
          onChange={(value) => formik.setFieldValue("dateOfBirth", value)}
          label="Date of Birth: *"
          onBlur={formik.handleBlur}
          isInvalid={Boolean(
            formik.touched.dateOfBirth && formik.errors.dateOfBirth
          )}
          error={formik.errors.dateOfBirth}
          placeholder="Enter Date of Birth"
        /> */}

        <Select
          options={allStates}
          label="State: *"
          name="state"
          onChange={formik.handleChange}
          value={formik.values.state}
          onBlur={formik.handleBlur}
          error={formik.errors.state}
          isInvalid={Boolean(formik.touched.state && formik.errors.state)}
          placeholder="Enter State"
        />

        <div className="col-span-2 text-center">
          <Button
            isLoading={formik.isSubmitting}
            type="submit"
            className="w-32 bg-primary text-white"
          >
            Add
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddDriver;
