/* eslint-disable */

import {
  GoogleMap,
  Marker,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

interface PropTypes {
  latt?: number;
  lngg?: number;
  pickupLatt?: number;
  pickupLngg?: number;
  mapContainerClassName?: string;
}

const MapContainer = (props: PropTypes) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCOEv9J9J-13PxRqN6bkhIfri2QkOWZdtM",
  });

  const [center, setCenter] = useState({
    lat: props.latt || 0,
    lng: props.lngg || 0,
  });

  const mapRef = useRef<GoogleMap | null>(null);

  const handleMapLoaded = (e: google.maps.Map) => {
    const bounds = new google.maps.LatLngBounds();
    if (props.pickupLatt && props.pickupLngg) {
      bounds.extend({ lat: props.pickupLatt, lng: props.pickupLngg });
    }
    if (props.latt && props.lngg) {
      bounds.extend({ lat: props.latt, lng: props.lngg });
    }
    const center = bounds.getCenter();
    setCenter({ lat: center.lat(), lng: center.lng() });
    e.fitBounds(bounds);
  };
  const className = props.mapContainerClassName || "";

  return isLoaded ? (
    <GoogleMap
      onLoad={handleMapLoaded}
      ref={mapRef}
      center={center}
      zoom={12}
      options={{
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      }}
      mapContainerClassName={twMerge(className)}
      mapContainerStyle={{ width: "100%", height: "100%" }}
    >
      {props.latt && props.lngg && props.pickupLatt && props.pickupLngg ? (
        <Polyline
          path={[
            { lat: props.latt, lng: props.lngg },
            { lat: props.pickupLatt, lng: props.pickupLngg },
          ]}
        />
      ) : null}
      {props.latt && props.lngg && (
        <Marker
          icon={{
            url: "https://i.pinimg.com/originals/11/d8/11/11d811ec58d9ea2df6f7b28dcd176058.png",
            scaledSize: new window.google.maps.Size(50, 50),
          }}
          position={{ lat: props.latt, lng: props.lngg }}
        ></Marker>
      )}
      {props.pickupLatt && props.pickupLngg && (
        <Marker
          icon={{
            url: "https://cdn1.iconfinder.com/data/icons/logistics-delivery-set-3/512/29-512.png",
            scaledSize: new window.google.maps.Size(50, 50),
          }}
          position={{ lat: props.pickupLatt, lng: props.pickupLngg }}
        ></Marker>
      )}
    </GoogleMap>
  ) : (
    <p>loading...</p>
  );
};

export default MapContainer;
